import React from "react"
import {graphql, useStaticQuery} from "gatsby"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PortfolioItem from "../../components/portfolio-item";

import '../../components/index.scss'
import HomeLink from "../../components/home-link";

const StealsPage = () => {
  const imageQuery = useStaticQuery(graphql`
    query {
      icon: file(relativePath: { eq: "portfolio/steals/icon.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero01: file(relativePath: { eq: "portfolio/steals/01.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero02: file(relativePath: { eq: "portfolio/steals/02.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      hero03: file(relativePath: { eq: "portfolio/steals/03.png" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const title = 'Steals';
  const summary = `Stepping into native iOS Development for the first time, the revamped Steals.com App was developed
  used Swift 1.0 and quickly rose as the fifth source of traffic for the company. I worked as a Full-Stack and Mobile
  developer in this project, did all the work in the back-end to support the functionality, a sitelet to promote it and
  wrote the app and hit the publish button at the end.`;

  return <Layout>
    <SEO title={title} description={summary}/>
    <HomeLink/>
    <PortfolioItem
      frameworks={'Zend, Doctrine'}
      imageQuery={imageQuery}
      name={title}
      role={'iOS & PHP Developer'}
      stack={'Swift, PHP'}
      year={2015}
      url={'https://apps.apple.com/us/app/steals-com-daily-deals-on-brands-you-love/id407564886#?platform=iphone'}
      summary={summary}
    />
  </Layout>
};

export default StealsPage
